<template>
  <div class="faq">
    <div class="container">
      <div class="row">
        <div class="faq__title">
          FAQ
        </div>
        <div class="faq__list row">
          <div class="col-md-6" v-for="(item, index) in faqList.data" :key="index">
            <u-animate-container>
              <u-animate
                  name="fadeIn"
                  delay="0s"
                  duration="1s"
                  :iteration="1"
                  :offset="0"
                  animateClass="animate__fadeInUp"
                  :begin="false"
              >
                <div class="faq__item">
              <div class="faq__item-title">
                {{item.name}}
                <span
                    class="faq__item-open"
                    @click="openFaq !== index ? openFaq = index : openFaq = null"
                    :class="openFaq === index ? 'is-open' : null"
                />
              </div>

              <transition name="faq-show">
                <div class="faq__item-text" v-if="openFaq === index">
                  {{item.message}}
                </div>
              </transition>
            </div>
              </u-animate>
            </u-animate-container>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
  import {mapGetters} from "vuex";
  export default {
    data(){
      return{
        openFaq: null,
      }
    },
    computed:{
      ...mapGetters({
        faqList:'landing/faqList'
      })
    }
  }
</script>
<style lang="scss" scoped>
@import "src/assets/styles/styles";
.faq{
  padding: 110px 0 100px;
  background-color: $blue;
  background-image: url("~@/assets/images/faq-bg.png");
  background-position: center top;
  background-size: 100% auto;
  background-repeat: no-repeat;
  &__title{
    font-family: 'Mont', Arial;
    font-size: 96px;
    color: $white;
    font-weight: 900;
    position: relative;
    margin-bottom: 80px;
    @media screen and (max-width: 1220px){
      font-size: 66px;
    }
    @media screen and (max-width: 720px){
      font-size: 46px;
    }
    &:before{
      content: '';
      position: absolute;
      left: 210px;
      top: -15px;
      height: 38px;
      width: 38px;
      background-color: $yellow;
      border-radius: 50%;
    }
  }
  &__item{
    padding: 10px 20px;
    position: relative;
    border-bottom: 1px solid $white;
  }
  &__item-title{
    font-family: 'Mont', Arial;
    padding: 0 45px 0 35px;
    font-size: 18px;
    color: $white;
    font-weight: 900;
    margin-bottom: 10px;
    min-height: 52px;
    display: flex;
    align-items: center;
  }
  &__item-text{
    font-family: 'Inter', Arial;
    padding: 0px 10px 15px 35px;
    font-size: 16px;
    font-weight: 400;
    line-height: 21px;
    color: white;
    z-index: 1;
  }
  &__item-open{
    position: absolute;
    right: 25px;
    top: 20px;
    height: 25px;
    width: 25px;
    cursor: pointer;
    &:after,&:before{
      content: '';
      position: absolute;
      right: 0;
      top: 50%;
      height: 1px;
      width: 100%;
      background-color: $white;
      transition: 0.2s all;
    }
    &:before{
      transform: rotate(90deg);
    }
    &.is-open{
      &:before{
        transform: rotate(0deg);
      }
    }
  }
  &__list{
    @media screen and (max-width: 800px){
      margin: 0 !important;
    }
  }
}
</style>
